<template>
  <div>
    <div v-if="!isEmpty" class="schedule-an-interview">
      <div class="header">
        <van-skeleton title row="1" :loading="loading">
          <div class="title">{{ baseInfoData.candidateName }}，您好！</div>
          <div class="subtitle">{{ id == undefined || id == '' ? '欢迎使用预约面试功能，请您选择方便的时间参加面试' : '您还剩' + modifyPermit + '次修改面试的机会' }}</div>
        </van-skeleton>
      </div>
      <div class="body">
        <div class="card">
          <van-skeleton title row="3" :loading="loading">
            <div class="title">面试基本信息</div>
            <div class="form">
              <div class="form-row">
                <div class="form-label">公司名称：</div>
                <div class="form-content">{{ baseInfoData.companyName }}</div>
              </div>
              <div v-if="baseInfoData.interviewMode == interviewMode.offlineInterview" class="form-row">
                <div class="form-label">面试地址：</div>
                <div class="form-content">{{ baseInfoData.interviewAddr }}</div>
              </div>
            </div>
          </van-skeleton>
        </div>
        <div class="select-date">
          <van-skeleton title row="3" :loading="loading">
            <div class="title">请选择以下时间段：</div>
            <div class="select-button">
              <div class="button" v-for="(item, index) in baseInfoData.interviewDetails" :key="index" :class="{ active: selectDate == item.date }" @click="selectDateClick(item)">
                <span>{{ getFormatDate(item.date) }}</span>
                <span>{{ getDayWeek(item.date) }}</span>
              </div>
            </div>
          </van-skeleton>
        </div>
        <div class="m-divider"></div>
        <div class="select-time">
          <van-skeleton title row="3" :loading="loading">
            <div class="morning" v-if="morningPeriod && morningPeriod.length > 0">
              <div class="title">上午场次：</div>
              <div class="select-button">
                <template v-for="(item, index) in morningPeriod">
                  <div class="button" v-if="item.useStatus != 1" :key="index" :class="{ active: groupInfoId == item.groupInfoId, disabled: item.num == 0 }" @click="selectTimeClick(item)">
                    <span>{{ item.time }}</span>
                    <span>剩余{{ item.num }}人可约</span>
                    <span class="right"></span>
                  </div>
                </template>
              </div>
            </div>
            <div class="afternoon" v-if="afterNoonPeriod && afterNoonPeriod.length > 0">
              <div class="title">下午场次：</div>
              <div class="select-button">
                <template v-for="(item, index) in afterNoonPeriod">
                  <div class="button" v-if="item.useStatus != 1" :key="index" :class="{ active: groupInfoId == item.groupInfoId, disabled: item.num == 0 }" @click="selectTimeClick(item)">
                    <span>{{ item.time }}</span>
                    <span>剩余{{ item.num }}人可约</span>
                    <span class="right"></span>
                  </div>
                </template>
              </div>
            </div>
          </van-skeleton>
        </div>
      </div>
      <div class="footer" v-show="!loading">
        <van-submit-bar button-text="我选好了" @submit="onSubmit()">
          <div class="feedback-tip" @click="feedbackClick()">时间都不合适，反馈给我们</div>
        </van-submit-bar>
      </div>
      <van-dialog v-model="feedbackShow" show-cancel-button confirm-button-color="#1890FF" @confirm="confirmFeedback()" @cancel="cancel()">
        <div class="feedback-text">确认反馈时间不合适吗？反馈后您将不参与本次面试。</div>
      </van-dialog>
    </div>
    <div v-if="isEmpty" class="interview-empty">
      <img src="@/assets/no-data-logo.png" />
      <p>{{ errMsg }}</p>
    </div>
  </div>
</template>
<script>
import { getReserveCandidateInfo, createReserve, getReserveInfoByJobseeker } from '../../api/interview'
import moment from 'moment'

export default {
  name: 'ScheduleAnInterview',
  data: () => {
    return {
      loading: true,
      id: '',
      jobseekerChannelId: '',
      reserveId: '',
      // 界面渲染参数
      feedbackShow: false,

      baseInfoData: {},
      morningPeriod: [],
      afterNoonPeriod: [],

      selectDate: '',
      selectTime: '',
      groupInfoId: '',
      modifyPermit: 0,

      interviewMode: {
        offlineInterview: 0,
        onlineInterview: 1,
        tencentInterview: 3,
      },
      isEmpty: false,
      errMsg: '',
    }
  },
  created() {
    // 获取参数信息
    this.id = this.$route.query.id
    this.jobseekerChannelId = this.$route.query.jobseekerChannelId
    this.reserveId = this.$route.query.reserveId
    const param = { jobseekerChannelId: this.jobseekerChannelId, reserveId: this.reserveId }
    // 获取候选人基础参数
    getReserveCandidateInfo(param).then(res => {
      if (res && res.code === 0) {
        this.loading = false
        if (res.data.taskStatus !== 0) {
          this.isEmpty = true
          this.errMsg = '面试已取消/生成,无法操作,请联系hr处理'
          // this.$toast({ type: 'error', message: '面试已取消/生成,无法操作,请联系hr处理' })
          return
        }
        if (res.data.taskCloseTime !== null) {
          const lastTime = new Date(res.data.taskCloseTime)
          if (new Date() > lastTime) {
            this.isEmpty = true
            this.errMsg = '已到达预约截止时间，无法进行预约'
            // this.$toast({ type: 'error', message: '已到达预约截至时间，无法进行预约' })
            return
          }
        }
        this.isEmpty = false
        this.errMsg = ''

        res.data.interviewDetails.sort((o1, o2) => {
          return o1.date > o2.date ? 1 : o1.date < o2.date ? -1 : 0
        })
        this.baseInfoData = res.data
        if (!this.id) {
          this.selectDate = res.data.interviewDetails[0]?.date
          this.morningPeriod = res.data.interviewDetails[0]?.morningPeriod
          this.afterNoonPeriod = res.data.interviewDetails[0]?.afterNoonPeriod
        } else {
          getReserveInfoByJobseeker(param).then(res1 => {
            if (res1.code === 0) {
              if (res1.data.modifyPermit === 0) {
                this.$router.push({
                  path: '/schedule-an-interview/success-reserve',
                  query: param,
                })
              } else {
                this.modifyPermit = res1.data.modifyPermit
                if (res1.data.date) {
                  const date = new Date(res1.data.date)
                  this.selectDate = moment(date).format('YYYY-MM-DD')
                  this.groupInfoId = res1.data.groupInfoId
                  this.echoData(res.data.interviewDetails)
                }
              }
            }
          })
        }
      } else {
        this.$toast({ type: 'error', message: res.msg })
      }
    })
    // 判断id是否存在，进行修改
    if (!this.id) {
      getReserveInfoByJobseeker(param).then(res => {
        if (res.code === 0) {
          if (res.data !== null) {
            if (res.data.date === null || res.data.groupInfoId === null) {
              this.$router.push({
                path: '/schedule-an-interview/feedback-success',
              })
            } else {
              this.$router.push({
                path: '/schedule-an-interview/success-reserve',
                query: param,
              })
            }
          }
        }
      })
    }
  },
  methods: {
    echoData(data) {
      data.forEach(item => {
        if (item.date === this.selectDate) {
          this.morningPeriod = item.morningPeriod
          this.afterNoonPeriod = item.afterNoonPeriod
        }
      })
    },
    onSubmit() {
      if (this.groupInfoId !== null && this.groupInfoId !== undefined && this.groupInfoId !== '') {
        this.$router.push({
          path: '/schedule-an-interview/confirm-reserve',
          query: {
            id: this.id,
            jobseekerChannelId: this.jobseekerChannelId,
            reserveId: this.reserveId,
            candidateName: this.baseInfoData.candidateName,
            companyName: this.baseInfoData.companyName,
            positionName: this.baseInfoData.positionName,
            interviewAddr: this.baseInfoData.interviewAddr,
            date: this.selectDate,
            time: this.selectTime,
            groupInfoId: this.groupInfoId,
            interviewMode: this.baseInfoData.interviewMode,
          },
        })
      } else {
        this.$toast({ type: 'error', message: '请选择场次！' })
      }
    },
    feedbackClick() {
      this.feedbackShow = true
    },
    cancel() {
      this.feedbackShow = false
    },
    confirmFeedback() {
      this.feedbackShow = false
      const param = {
        reserveId: this.reserveId,
        jobseekerChannelId: this.jobseekerChannelId,
        feedBackComment: 1,
      }
      createReserve(param).then(res => {
        if (res.code === 0) {
          this.$router.push({
            path: '/schedule-an-interview/feedback-success',
          })
        } else {
          this.$toast({ type: 'error', message: res.msg })
        }
      })
    },
    // 选中日期
    selectDateClick(rowData) {
      this.selectDate = rowData.date
      this.morningPeriod = rowData.morningPeriod
      this.afterNoonPeriod = rowData.afterNoonPeriod
    },
    selectTimeClick(rowData) {
      if (rowData.num === 0 || rowData.useStatus === 1) {
        this.$toast({ type: 'warning', message: '该场面试已被其他候选人预约，请重新预约' })
      } else {
        if (this.groupInfoId === rowData.groupInfoId) {
          this.groupInfoId = ''
        } else {
          this.groupInfoId = rowData.groupInfoId
          this.selectTime = rowData.time
        }
      }
    },
    getFormatDate(date) {
      return moment(date).format('MM月DD日')
    },
    // 获取对应天数的星期
    getDayWeek(date) {
      const chineseWeek = ['日', '一', '二', '三', '四', '五', '六']
      const week = new Date(date).getDay()
      return '周' + chineseWeek[week]
    },
  },
}
</script>
<style lang="scss" scoped>
.schedule-an-interview {
  background: url(../../assets/schedule-interview-header-bg.png) no-repeat;
  background-size: 100%;
  height: 100vh;
  .header {
    padding-top: 32px;
    padding-left: 30px;
    padding-bottom: 11px;
    .title,
    .subtitle {
      font-family: PingFangSC-Semibold, PingFang SC;
      color: #ffffff;
    }
    .title {
      height: 30px;
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
    }
    .subtitle {
      margin-top: 7px;
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
    }
  }
  .body {
    .card {
      margin: 0 20px;
      background: #ffffff;
      box-shadow: 0px 0px 4px 0px rgba(141, 141, 141, 0.5);
      border-radius: 10px;
      padding: 24px 22px 23px 22px;
      .title {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 25px;
      }
      .form {
        padding-top: 18px;
        .form-row + .form-row {
          padding-top: 4px;
        }
        .form-row {
          display: flex;
          .form-label,
          .form-content {
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            line-height: 21px;
            font-weight: 400;
          }
          .form-label {
            min-width: 2.6rem;
            margin-right: 10px;
            color: #979797;
          }
          .form-content {
            color: #333333;
          }
        }
      }
    }
    .select-date {
      padding: 46px 30px 24px 30px;
      .title {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 25px;
        padding-bottom: 15px;
      }
      .select-button {
        margin-left: -9px;
        .active {
          background: #1890ff;
          color: #ffffff;
          border-color: #1890ff;
        }
        .button {
          margin-left: 9px;
          margin-right: 9px;
          margin-bottom: 18px;
          border-radius: 5px;
          border: 1px solid #dddddd;
          padding: 8px 18px;
          border-radius: 5px;
          display: inline-block;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          span {
            display: block;
          }
        }
      }
    }
    .m-divider {
      height: 14px;
      background: #f5f5f5;
    }
    .select-time {
      padding: 25px 30px;
      .afternoon {
        padding-top: 7px;
      }
      .morning,
      .afternoon {
        .title {
          padding-bottom: 14px;
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 25px;
        }
        .select-button {
          margin-left: -9px;
          .button {
            margin-left: 9px;
            margin-right: 9px;
            margin-bottom: 18px;
            padding: 8px 7px 7px 14px;
            height: 58px;
            border-radius: 5px;
            border: 1px solid #dddddd;
            display: inline-block;
            span {
              height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              line-height: 20px;
            }
            span:first-child {
              color: #333333;
              display: block;
            }
            span:nth-child(2) {
              color: #979797;
            }
          }
          .disabled {
            background: #eaeaea;
            border: 1px solid #dddddd;
            span:first-child {
              color: #979797;
            }
          }
          .active {
            border: 1.5px solid #1890ff;
            .right {
              position: relative;
            }
            .right::after {
              opacity: 1;
              content: '';
              position: absolute;
              width: 8px;
              height: 15px;
              background: transparent;
              border: 3px solid #1890ff;
              border-top: none;
              border-left: none;
              border-bottom-right-radius: 5px;
              top: 6px;
              right: -6px;
              transform: rotate(39deg);
              -webkit-transform: rotate(39deg);
              -moz-transform: rotate(39deg);
              -o-transform: rotate(39deg);
              -ms-transform: rotate(39deg);
              transform: rotate(39deg);
            }
          }
        }
      }
    }
  }
  .footer {
    height: 71px;
    .van-submit-bar {
      background: #ffffff;
      box-shadow: 0px -2px 4px 0px rgba(103, 116, 140, 0.1);
      height: 71px;
    }
    .van-submit-bar__bar {
      height: 58px;
      margin-bottom: 13px;
      padding: 9px 20px;
    }
    .feedback-tip {
      height: 40px;
      border-radius: 21px;
      border: 1px solid #dddddd;
      padding: 0px 10px;
      line-height: 40px;
      text-align: center;
      margin-right: 16px;
    }
    .van-button {
      height: 40px;
      background: #4f8fff;
      border-radius: 21px;
      .van-button__text {
        width: auto;
        height: 21px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 21px;
      }
    }
  }
  .feedback-text {
    padding: 33px 20px 41px 20px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 25px;
  }
}
.interview-empty {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 250px;
  }
}
</style>
